import elliScript from '@elliemae/em-ssf-guest';
import {
    Cancel, Delete, Edit, Info, Save
} from '@mui/icons-material';
import {
    Alert,
    Checkbox, DialogContent, MenuItem, Button as MuiButton, Select,
    Typography
} from '@mui/material';
import {
    BooleanDisplay,
    Button, CardTable, Dialog, DialogActions, DialogProps,
    IconButton, IconTypography, useAsyncEffect, useConfirm, usePageMessage
} from '@tsp-ui/core';
import clsx from 'clsx';
import React, {
    ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState
} from 'react';
import {
    Controller, FormProvider, useFieldArray, useForm,
    useFormContext
} from 'react-hook-form';

import {
    AdminSettings,
    PersonaConfig, fetchAdminSettings, updateAdminSettings
} from '../api';
import { ProgramDetailsView } from '../api/api-utils';

import styles from './AdminSettingsDialog.module.scss';


interface AdminSettingsDialogProps extends Omit<DialogProps, 'title'> {
    onEditConditionsSettings: () => void;
    loanHasEnhancedConditionsEnabled: boolean | undefined;
    personaNames: string[] | undefined;
}

export default function AdminSettingsDialog({
    open, onEditConditionsSettings, loanHasEnhancedConditionsEnabled, personaNames, ...props
}: AdminSettingsDialogProps) {
    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ personas, setPersonas ] = useState<EPCPersona[]>([]);
    const [ newConfigId, setNewConfigId ] = useState<string | null>(null);
    const [ personasError, setPersonasError ] = useState(false);

    const formMethods = useForm<AdminSettings>();
    const {
        control, handleSubmit, reset, watch
    } = formMethods;
    const { fields: personaConfigs, append, remove } = useFieldArray<AdminSettings, 'personaConfigs'>({
        control,
        name: 'personaConfigs'
    });

    const watchPersonaConfigs = watch('personaConfigs');

    const fetchPersonas = useCallback(async () => {
        try {
            const applicationObject = await elliScript.getObject('application');
            const personas = await applicationObject.performAction('getPersonas');

            if (!personas || personas.length === 0) {
                console.warn('Received empty personas list. Possible permission error.');
                setPersonasError(true);
                return [];
            }

            setPersonasError(false);
            return personas;
        } catch (error) {
            console.error('Error fetching personas:', error);
            setPersonasError(true);
            return [];
        }
    }, []);

    useAsyncEffect(useCallback(async () => {
        try {
            if (open) {
                setLoading(true);
                const [ settings, personas ] = await Promise.all([
                    fetchAdminSettings(),
                    fetchPersonas()
                ]);

                reset(settings);
                setPersonas(personas);
                setNewConfigId(null);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the admin settings', error);
        }

        setLoading(false);
    }, [
        open, fetchPersonas, reset, pageMessage
    ]));

    async function onSubmit(settings: AdminSettings) {
        const hasAdmin = settings.personaConfigs.some(config => config.isAdmin);
        if (!hasAdmin) {
            pageMessage.error('At least one persona must have admin permissions');
            return;
        }

        setSaveLoading(true);
        try {
            await updateAdminSettings(settings);
            props.onClose?.({}, 'escapeKeyDown');
            pageMessage.success('Admin settings updated successfully');
        } catch (error) {
            pageMessage.handleApiError('Failed to update admin settings', error);
        } finally {
            setSaveLoading(false);
        }
    }

    function handleAddConfig() {
        const unusedPersonas = personas.filter(p => !personaConfigs.some(config => config.personaId === p.id));
        if (unusedPersonas.length > 0) {
            const newConfig: PersonaConfig = {
                personaId: unusedPersonas[0].id,
                name: unusedPersonas[0].name,
                isAdmin: false,
                canSelectPrograms: false,
                canImportConditions: false,
                programDetailsView: ProgramDetailsView.LO
            };
            append(newConfig);
            setNewConfigId(newConfig.personaId);
        }
    }

    async function handleRemoveConfig(index: number) {
        const configToRemove = personaConfigs[index];
        const adminConfigs = watchPersonaConfigs.filter(config => config.isAdmin);

        if (configToRemove.isAdmin && adminConfigs.length === 1) {
            pageMessage.error('Cannot remove the only persona with admin permissions');
            return;
        }

        const isCurrentUserPersona = personaNames?.includes(configToRemove.name);
        const isLastAdminForUser = configToRemove.isAdmin
            && adminConfigs.filter(config => personaNames?.includes(config.name)).length === 1;

        if (isCurrentUserPersona && isLastAdminForUser) {
            const confirmed = await confirm(
                <Typography>
                    You are about to remove your last admin persona. This will revoke
                    your ability to edit these settings in the future. Are you sure you want to proceed?
                </Typography>
            );
            if (!confirmed) {
                return;
            }
        }

        remove(index);
        setNewConfigId(null);
    }

    return (
        <Dialog
            title="Admin Settings"
            loading={loading}
            open={open}
            {...props}
            maxWidth="lg"
        >
            <DialogContent>
                {personasError && (
                    <Alert
                        severity="warning"
                        className={styles.alert}
                    >
                        Your user persona does not have permission to view Personas in Encompass. Please
                        contact your Encompass admin to make changes to persona permissions in this service.
                    </Alert>
                )}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    id={adminSettingsFormId}
                >
                    <FormProvider {...formMethods}>
                        <CardTable
                            headers={personaConfigs.length > 0 ? adminSettingsHeaders : [ 'No personas have been configured yet' ]}
                            className={styles.tableRoot}
                            tableClassName={clsx({
                                [styles.noConfigs]: personaConfigs.length === 0
                            })}
                        >
                            {personaConfigs.map((config, index) => (
                                <PersonaSettingsRow
                                    key={config.id}
                                    index={index}
                                    remove={handleRemoveConfig}
                                    personas={personas}
                                    setNewConfigId={setNewConfigId}
                                    isNewConfig={config.personaId === newConfigId}
                                    allConfigs={watchPersonaConfigs}
                                    personaNames={personaNames}
                                    personasError={personasError}
                                />
                            ))}
                        </CardTable>

                        <IconTypography
                            variant="caption"
                            icon={(
                                <Info
                                    color="primary"
                                    fontSize="small"
                                    className={styles.infoIcon}
                                />
                            )}
                            className={styles.infoIconWrapper}
                        >
                            Only personas in this list will be permitted to access this service
                        </IconTypography>
                    </FormProvider>
                </form>
            </DialogContent>


            <DialogActions loading={saveLoading}>
                <Button
                    onClick={handleAddConfig}
                    disabled={personasError || personaConfigs.length === personas.length}
                    tooltip={personaConfigs.length === personas.length ? 'All available personas have been added' : ''}
                >
                    Add Persona
                </Button>

                <Button
                    onClick={onEditConditionsSettings}
                    disabled={personasError || loanHasEnhancedConditionsEnabled}
                    tooltip={loanHasEnhancedConditionsEnabled
                        ? 'Condition import configuration for enhanced conditions is managed in Services Management in the Encompass Admin portal.'
                        : ''}
                >
                    Edit conditions settings
                </Button>

                <MuiButton
                    variant="contained"
                    type="submit"
                    form={adminSettingsFormId}
                    disabled={personasError || saveLoading}
                >
                    Save
                </MuiButton>
            </DialogActions>
        </Dialog>
    );
}

const adminSettingsHeaders = [
    'Persona', 'Admin', 'Select Programs', 'Import Conditions', 'Program View', ''
];

const adminSettingsFormId = 'admin-settings-form-id';

interface PersonaSettingsRowProps {
    index: number;
    remove: (index: number) => void;
    personas: EPCPersona[];
    setNewConfigId: Dispatch<SetStateAction<string | null>>;
    isNewConfig: boolean;
    allConfigs: PersonaConfig[];
    personaNames: string[] | undefined;
    personasError: boolean;
}

function PersonaSettingsRow({
    index, remove, personas, setNewConfigId, isNewConfig, allConfigs, personaNames, personasError
}: PersonaSettingsRowProps) {
    const confirm = useConfirm();
    const { control, setValue, watch } = useFormContext();
    const [ isEditing, setIsEditing ] = useState(isNewConfig);

    useEffect(() => {
        if (isNewConfig) {
            setIsEditing(true);
        }
    }, [ isNewConfig ]);

    const currentPersonaId = watch(`personaConfigs.${index}.personaId`);
    const currentPersonaName = watch(`personaConfigs.${index}.name`);
    const currentPersonaIsAdmin = watch(`personaConfigs.${index}.isAdmin`);

    const availablePersonas = personas.filter(
        persona => persona.id === currentPersonaId || !allConfigs.some(config => config.personaId === persona.id)
    );

    async function handleAdminChange(event: ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.checked;
        if (!newValue) {
            const adminConfigs = allConfigs.filter(config => config.isAdmin);
            const isCurrentUserPersona = personaNames?.includes(currentPersonaName);
            const isLastAdminForUser = currentPersonaIsAdmin
            && adminConfigs.filter(config => personaNames?.includes(config.name)).length === 1;

            if (isLastAdminForUser && isCurrentUserPersona) {
                const confirmed = await confirm(
                    <Typography>
                        You are about to remove your last admin persona. This will revoke
                        your ability to edit these settings in the future. Are you sure you want to proceed?
                    </Typography>
                );
                if (!confirmed) {
                    return;
                }
            }
        }
        setValue(`personaConfigs.${index}.isAdmin`, newValue);
    }

    return (
        <tr>
            <Typography component="td">
                <Controller
                    name={`personaConfigs.${index}.name`}
                    control={control}
                    render={({ field }) => (
                        !isEditing ? (
                            field.value
                        ) : (
                            <Select
                                {...field}
                                onChange={(e) => {
                                    const selectedPersona = availablePersonas.find(p => p.name === e.target.value);
                                    if (selectedPersona) {
                                        field.onChange(e.target.value);
                                        setValue(`personaConfigs.${index}.personaId`, selectedPersona.id);
                                    }
                                }}
                                variant="standard"
                                className={styles.personaName}
                            >
                                {availablePersonas.map(persona => (
                                    <MenuItem
                                        key={persona.id}
                                        value={persona.name}
                                    >
                                        {persona.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )
                    )}
                />
            </Typography>

            <Typography component="td">
                <Controller
                    name={`personaConfigs.${index}.isAdmin`}
                    control={control}
                    render={({ field }) => (
                        isEditing ? (
                            <Checkbox
                                {...field}
                                checked={field.value}
                                onChange={handleAdminChange}
                            />
                        ) : (
                            <BooleanDisplay value={field.value} />
                        )
                    )}
                />
            </Typography>

            <Typography component="td">
                <Controller
                    name={`personaConfigs.${index}.canSelectPrograms`}
                    control={control}
                    render={({ field }) => (
                        isEditing ? (
                            <Checkbox
                                {...field}
                                checked={field.value}
                            />
                        ) : (
                            <BooleanDisplay value={field.value} />
                        )
                    )}
                />
            </Typography>

            <Typography component="td">
                <Controller
                    name={`personaConfigs.${index}.canImportConditions`}
                    control={control}
                    render={({ field }) => (
                        isEditing ? (
                            <Checkbox
                                {...field}
                                checked={field.value}
                            />
                        ) : (
                            <BooleanDisplay value={field.value} />
                        )
                    )}
                />
            </Typography>

            <Typography component="td">
                <Controller
                    name={`personaConfigs.${index}.programDetailsView`}
                    control={control}
                    render={({ field }) => (
                        !isEditing ? (
                            field.value
                        ) : (
                            <Select
                                {...field}
                                variant="standard"
                            >
                                <MenuItem value="LO">LO</MenuItem>

                                <MenuItem value="UW">UW</MenuItem>
                            </Select>
                        )
                    )}
                />
            </Typography>

            <Typography
                component="td"
                className={styles.iconButtonWrapper}
            >
                {isEditing ? (
                    <>
                        <IconButton
                            onClick={() => {
                                setIsEditing(false);
                                setNewConfigId(null);
                            }}
                            tooltip="Save"
                        >
                            <Save color="secondary" />
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                if (isNewConfig) {
                                    remove(index);
                                }
                                setIsEditing(false);
                            }}
                            tooltip="Cancel"
                        >
                            <Cancel color="error" />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton
                            onClick={() => setIsEditing(true)}
                            tooltip={personasError ? '' : 'Edit'}
                            disabled={personasError}
                        >
                            <Edit color="secondary" />
                        </IconButton>

                        <IconButton
                            onClick={() => remove(index)}
                            tooltip={personasError ? '' : 'Delete'}
                            disabled={personasError}
                        >
                            <Delete color="error" />
                        </IconButton>
                    </>
                )}
            </Typography>
        </tr>
    );
}
