import elliScript from '@elliemae/em-ssf-guest';
import { DialogContent, Typography } from '@mui/material';
import { Dialog, useAsyncEffect, usePageMessage } from '@tsp-ui/core';
import { useCallback, useState } from 'react';

import { DPRProgram, fetchProgramDetailsHTML } from '../api';
import { ProgramDetailsView } from '../api/api-utils';

import styles from './ProgramDetailsDialog.module.scss';
import { ProgramDetailsSection, ProgramDetailsSectionProps } from './ProgramDetailsSection';


async function onDetailsDialogLinkClick(event: MouseEvent) {
    event.preventDefault();

    const applicationObject = await elliScript.getObject('application');
    await applicationObject.open({
        target: (event.target! as HTMLAnchorElement).href
    });
}

const windowFnName = 'ProgramDetailsDialog_onDetailsDialogLinkClick';

// @ts-ignore
window[windowFnName] = onDetailsDialogLinkClick;

interface ProgramDetailsDialogProps {
    onClose: () => void;
    dprProgram: DPRProgram | undefined;
    open: boolean;
    programDetailsView: ProgramDetailsView | undefined;
    transactionId: string | null;
}

export default function ProgramDetailsDialog({
    onClose, dprProgram, open, programDetailsView, transactionId
}: ProgramDetailsDialogProps) {
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);
    const [ sections, setSections ] = useState<ProgramDetailsSectionProps[]>();
    const [ footer, setFooter ] = useState('');

    const uuid = dprProgram?.uuid;
    const showUwContent = programDetailsView === ProgramDetailsView.UW;

    useAsyncEffect(useCallback(async () => {
        try {
            if (uuid) {
                setLoading(true);
                const html = await fetchProgramDetailsHTML(uuid, transactionId, showUwContent);

                const div = document.createElement('div');
                div.innerHTML = html;

                const anchors = div.querySelectorAll('a');

                anchors.forEach((anchor) => {
                    if (!anchor.href?.includes('tel') && !anchor.href?.includes('mailto')) {
                        anchor.setAttribute(
                            'onclick', `${windowFnName}(event)`
                        );
                    }
                });

                const tables = div.querySelectorAll('table.program-details');

                setSections([ ...tables ].map((table) => {
                    const thead = table.querySelector('thead')!;
                    const header = thead.textContent?.trim();

                    table.removeChild(thead);

                    return {
                        html: table.outerHTML,
                        header: header || ''
                    };
                }));

                setFooter(div.querySelector('#print-footer')?.innerHTML || '');
            } else {
                setSections(undefined);
                setFooter('');
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the admin settings', error);
        }

        setLoading(false);
    }, [
        pageMessage, uuid, showUwContent, transactionId
    ]));

    return (
        <Dialog
            title={(
                <div>
                    {dprProgram?.name}

                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        DPR-{dprProgram?.program_model_id}
                    </Typography>
                </div>
            )}
            loading={loading}
            open={open}
            onClose={onClose}
            maxWidth="lg"
            PaperProps={{ className: styles.dialogPaper }}
        >
            <DialogContent>
                <Typography
                    component="div"
                    className={styles.dialogContent}
                >
                    {sections?.map((sectionProps) => (
                        <ProgramDetailsSection
                            key={sectionProps.header}
                            {...sectionProps}
                        />
                    ))}

                    {!footer ? null : (
                        <Typography
                            component="div"
                            variant="body2"
                            align="center"
                            className={styles.footer}
                            dangerouslySetInnerHTML={{ __html: footer }}
                        />
                    )}
                </Typography>
            </DialogContent>
        </Dialog>
    );
}
