import elliScript from '@elliemae/em-ssf-guest';
import {
    AssignmentLate, AssignmentReturned, AssignmentTurnedIn, Check, ErrorOutline, FormatListBulleted, OpenInNew,
    PendingActions, PushPin, PushPinOutlined, WarningAmber
} from '@mui/icons-material';
import {
    Button, CircularProgress, Tooltip, Typography
} from '@mui/material';
import {
    IconButton, LabeledValue, PaperSaveLoader, formatCurrency, formatDistanceToNowStrict,
    replaceItemByKey, useConfirm, usePageMessage
} from '@tsp-ui/core';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import React, {
    Dispatch, ReactNode, SetStateAction, useState
} from 'react';

import {
    ClientProgram, ClientProgramSnapshot, ConditionImportStatus, DPRProgram,
    GetProgramsResponse, IsDevConnectAdminResponse, importConditions, updatePinnedPrograms, updateProgram
} from '../api';
import { updateSelectedProgramResults } from '../utils/updateSelectedProgramResults';

import styles from './ProgramResultRow.module.scss';
import { tooltipTitle } from './tooltipTitle';


const {
    FAILED, SUCCESS, LOCK_FAILED, PROCESSING
} = ConditionImportStatus;

interface ProgramResultRowProps {
    dprProgram: DPRProgram | null;
    clientProgram: ClientProgram | null;
    setProgramResults: Dispatch<SetStateAction<GetProgramsResponse | undefined>>;
    openDetailsDialog: (programUuid: string) => void;
    openSetupDialog: (programId: number) => void;
    disableSelection?: boolean;
    setImportConditionsProgramId: Dispatch<SetStateAction<number | undefined>>;
    setNumConditionsToImport: Dispatch<SetStateAction<number | undefined>>;
    hasSelectPermission: boolean | undefined;
    hasImportPermission: boolean | undefined;
    devConnectAdminPromise: Promise<IsDevConnectAdminResponse>;
    finalDispositionReached: boolean;
    pinnedProgramIds: number[] | undefined;
    loanHasEnhancedConditionsEnabled: boolean | undefined;
}

export default function ProgramResultRow({
    dprProgram, clientProgram, setProgramResults, openDetailsDialog, openSetupDialog, disableSelection,
    setImportConditionsProgramId, setNumConditionsToImport, hasSelectPermission, hasImportPermission,
    devConnectAdminPromise, finalDispositionReached, pinnedProgramIds, loanHasEnhancedConditionsEnabled
}: ProgramResultRowProps) {
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const [ selectProgramLoading, setSelectProgramLoading ] = useState(false);
    const [ pinProgramLoading, setPinProgramLoading ] = useState(false);
    const [ importConditionsLoading, setImportConditionsLoading ] = useState(false);

    const {
        dprLoanProgramName,
        maxLoanAmount,
        maxLoanAmountType,
        maxLoanAmountCap,
        maxLoanAmountNotes,
        calculatedAssistanceAmount,
        snapshotLastChanged,
        snapshotWhenSelected
    } = !dprProgram ? clientProgram! : {
        dprLoanProgramName: dprProgram.name,
        maxLoanAmount: dprProgram.max_loan_amount,
        maxLoanAmountType: dprProgram.max_loan_amount_type,
        maxLoanAmountCap: dprProgram.max_loan_amount_cap,
        maxLoanAmountNotes: dprProgram.max_loan_amount_notes,
        calculatedAssistanceAmount: dprProgram.calculatedAssistanceAmount,
        snapshotLastChanged: clientProgram?.snapshotLastChanged || null,
        snapshotWhenSelected: clientProgram?.snapshotWhenSelected || {
            maxLoanAmountType: null,
            maxLoanAmount: null,
            maxLoanAmountNotes: null,
            maxLoanAmountCap: null,
            calculatedAssistanceAmount: null
        }
    };

    async function selectProgram() {
        setSelectProgramLoading(true);

        try {
            const updatedProgram = await updateProgram(dprProgram!.program_model_id, { isSelected: true });

            setProgramResults((programResults) => ({
                ...programResults!,
                selectedPrograms: programResults!.selectedPrograms.concat(updatedProgram)
            }));

            pageMessage.success('Program selected');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while selecting the program', error);
        }

        setSelectProgramLoading(false);
    }

    async function deselectProgram() {
        if (await confirm((
            <Typography>
                Are you sure you want to deselect this program?
            </Typography>
        ))) {
            setSelectProgramLoading(true);

            try {
                const updatedProgram = await updateProgram(clientProgram!.dprLoanProgramId, {
                    isSelected: false
                });

                setProgramResults((programResults) => ({
                    ...programResults!,
                    selectedPrograms: programResults!.selectedPrograms.filter((selectedProgram) => (
                        selectedProgram.dprLoanProgramId !== updatedProgram.dprLoanProgramId
                    ))
                }));

                pageMessage.success('Program deselected');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deselecting the program', error);
            }

            setSelectProgramLoading(false);
        }
    }

    async function pinOrUnpinProgram() {
        setPinProgramLoading(true);
        const currentlyPinned = dprProgram!.isPinned;

        try {
            const newPinnedPrograms = currentlyPinned
                ? (pinnedProgramIds || []).filter(pinnedId => pinnedId !== dprProgram!.program_model_id)
                : (pinnedProgramIds || []).concat(dprProgram!.program_model_id);

            await updatePinnedPrograms(newPinnedPrograms);

            setProgramResults((programResults) => ({
                ...programResults!,
                pinnedProgramIds: newPinnedPrograms,
                dprPrograms: replaceItemByKey(programResults!.dprPrograms, {
                    ...dprProgram!,
                    isPinned: !currentlyPinned
                }, 'program_model_id')
            }));

            pageMessage.success(currentlyPinned ? 'Program unpinned' : 'Program pinned');
        } catch (error) {
            pageMessage.handleApiError(
                `An error occurred while ${currentlyPinned ? 'unpinning' : 'pinning'} the program`, error
            );
        }

        setPinProgramLoading(false);
    }

    async function doImportConditions() {
        try {
            setImportConditionsLoading(true);

            const applicationObject = await elliScript.getObject('application');
            const { id } = await applicationObject.getDescriptor();

            const { isAdmin } = await devConnectAdminPromise;
            const isWeb = id !== 'ENC';

            if (!loanHasEnhancedConditionsEnabled || isWeb || !isAdmin || await confirm(
                <div className={styles.importConfirm}>
                    <WarningAmber
                        fontSize="large"
                        color="warning"
                    />

                    <Typography align="center">
                        Importing conditions will override your loan lock and any unsaved changes will be lost. Are you
                        sure you want to continue importing conditions?
                    </Typography>
                </div>
            )) {
                setImportConditionsProgramId(dprProgram!.program_model_id);

                const { conditionsToImport, enhancedConditionsImportComplete } = await importConditions(
                    dprProgram!.program_model_id, !isWeb && !isAdmin, null, loanHasEnhancedConditionsEnabled
                );

                if (enhancedConditionsImportComplete) {
                    updateSelectedProgramResults(setProgramResults, {
                        dprLoanProgramId: clientProgram!.dprLoanProgramId,
                        conditionsImportedStatus: ConditionImportStatus.SUCCESS
                    });
                    pageMessage.success('Enhanced conditions imported successfully');
                } else {
                    updateSelectedProgramResults(setProgramResults, {
                        dprLoanProgramId: clientProgram!.dprLoanProgramId,
                        conditionsImportedStatus: ConditionImportStatus.PROCESSING
                    });
                }
                setNumConditionsToImport(conditionsToImport);
            }
        } catch (error) {
            setImportConditionsProgramId(undefined);
            pageMessage.handleApiError('An error occurred while importing conditions', error);
        }

        setImportConditionsLoading(false);
    }

    return (
        <tr>
            <td width="1%">
                <MaxAssistanceAmount
                    maxLoanAmount={maxLoanAmount}
                    maxLoanAmountType={maxLoanAmountType}
                    maxLoanAmountCap={maxLoanAmountCap}
                    maxLoanAmountNotes={maxLoanAmountNotes}
                    calculatedAssistanceAmount={calculatedAssistanceAmount}
                    snapshotLastChanged={snapshotLastChanged}
                    snapshotWhenSelected={snapshotWhenSelected}
                />
            </td>

            <td>
                <Typography>
                    {dprLoanProgramName}
                </Typography>
            </td>

            {clientProgram !== null && (
                <td className={clsx(styles.iconTableCell, styles.statusIconTableCell)}>
                    {!clientProgram.isActive || !clientProgram.isFunded ? (
                        <Tooltip
                            title={tooltipTitle({
                                'Program is no longer available': !clientProgram.isActive,
                                'Program no longer has funding available': !clientProgram.isFunded
                            })}
                        >
                            <ErrorOutline color="error" />
                        </Tooltip>
                    ) : clientProgram.conditionsImportedStatus === FAILED
                        || clientProgram.conditionsImportedStatus === LOCK_FAILED ? (
                            <Tooltip
                                title={tooltipTitle({
                                    'One or more conditions failed to import':
                                        clientProgram.conditionsImportedStatus === FAILED,
                                    'Failed to import conditions because the loan was locked':
                                        clientProgram.conditionsImportedStatus === LOCK_FAILED
                                })}
                            >
                                <AssignmentLate color="error" />
                            </Tooltip>
                        ) : clientProgram.conditionsImportedStatus === SUCCESS ? (
                            <Tooltip title="All conditions imported">
                                <AssignmentTurnedIn color="success" />
                            </Tooltip>
                        ) : clientProgram.conditionsImportedStatus === PROCESSING ? (
                            <Tooltip title="Conditions import in progress">
                                <PendingActions color="primary" />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Program selected">
                                <Check color="success" />
                            </Tooltip>
                        )}
                </td>
            )}

            <td className={styles.iconTableCell}>
                <div className={styles.iconTableCellButtons}>
                    <div>
                        {clientProgram !== null && (
                            <IconButton
                                tooltip={tooltipTitle({
                                    'The loan is no longer in an active status': finalDispositionReached,
                                    'View loan setup instructions': dprProgram,
                                    'Program no longer available': !dprProgram
                                })}
                                onClick={() => openSetupDialog(dprProgram!.program_model_id)}
                                disabled={finalDispositionReached || !dprProgram}
                            >
                                <FormatListBulleted color="secondary" />
                            </IconButton>
                        )}

                        <IconButton
                            tooltip={tooltipTitle({
                                'The loan is no longer in an active status': finalDispositionReached,
                                'View program details': dprProgram,
                                'Program no longer available': !dprProgram
                            })}
                            onClick={() => openDetailsDialog(dprProgram!.uuid)}
                            disabled={finalDispositionReached || !dprProgram}
                        >
                            <OpenInNew color="secondary" />
                        </IconButton>

                        {clientProgram === null && (
                            <IconButton
                                tooltip={tooltipTitle({
                                    'The loan is no longer in an active status': finalDispositionReached,
                                    'Pin Program': !dprProgram?.isPinned,
                                    'Unpin Program': dprProgram?.isPinned
                                })}
                                onClick={pinOrUnpinProgram}
                                disabled={finalDispositionReached || pinProgramLoading}
                                loading={pinProgramLoading}
                            >
                                {dprProgram?.isPinned ? (
                                    <PushPin color="secondary" />
                                ) : (
                                    <PushPinOutlined color="secondary" />
                                )}
                            </IconButton>
                        )}

                        {clientProgram !== null && (
                            <IconButton
                                tooltip={tooltipTitle({
                                    'The loan is no longer in an active status': finalDispositionReached,
                                    'You don\'t have permission to import conditions': !hasImportPermission,
                                    'Program no longer available': !dprProgram,
                                    'Import conditions': clientProgram.conditionsImportedStatus !== SUCCESS,
                                    'All conditions have already been imported':
                                        clientProgram.conditionsImportedStatus === SUCCESS
                                })}
                                onClick={doImportConditions}
                                disabled={finalDispositionReached || !dprProgram || !hasImportPermission
                                            || clientProgram.conditionsImportedStatus === SUCCESS
                                            || clientProgram.conditionsImportedStatus === PROCESSING}
                            >
                                {importConditionsLoading && !loanHasEnhancedConditionsEnabled ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <AssignmentReturned color="secondary" />
                                )}
                            </IconButton>
                        )}
                    </div>

                    <div className={styles.selectButtonContainer}>
                        {clientProgram !== null ? (
                            <Tooltip title={!hasSelectPermission ? 'You don\'t have permission to deselect programs' : undefined}>
                                <span>
                                    <Button
                                        size="small"
                                        onClick={deselectProgram}
                                        disabled={selectProgramLoading || !hasSelectPermission
                                            || finalDispositionReached}
                                    >
                                        Deselect
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={tooltipTitle({
                                    'You don\'t have permission to select programs': !hasSelectPermission,
                                    'The loan requires additional location information to select a program': disableSelection
                                })}
                            >
                                <span>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={selectProgram}
                                        disabled={selectProgramLoading || !hasSelectPermission
                                            || disableSelection || finalDispositionReached}
                                    >
                                        Select
                                    </Button>
                                </span>
                            </Tooltip>
                        )}

                        <PaperSaveLoader loading={selectProgramLoading} />
                    </div>
                </div>
            </td>
        </tr>
    );
}

interface MaxAssistanceAmountProps extends ClientProgramSnapshot, Pick<
    ClientProgram, 'snapshotWhenSelected' | 'snapshotLastChanged'
> {}

const ASSISTANCE_AMOUNT_TOOLTIP = 'The assistance amount has changed since this program was originally selected';

function MaxAssistanceAmount({
    maxLoanAmount,
    maxLoanAmountType,
    maxLoanAmountCap,
    maxLoanAmountNotes,
    calculatedAssistanceAmount,
    snapshotWhenSelected,
    snapshotLastChanged
}: MaxAssistanceAmountProps) {
    let assistanceAmountText: string | null = 'N/A';
    let assistanceAmountSecondaryText: string | null = null;
    let assistanceAmountChangedTooltip: ReactNode | null = null;

    function formatTooltip(formattedOriginalValue: ReactNode | null) {
        return !snapshotLastChanged ? null : (
            <div className={styles.tooltipBody}>
                <Typography
                    variant="body2"
                    align="center"
                >
                    {ASSISTANCE_AMOUNT_TOOLTIP}
                </Typography>

                <div className={styles.originalValueLabeledField}>
                    <LabeledValue
                        label="Original value"
                        value={formattedOriginalValue || '--'}
                        variants={{
                            value: 'body1'
                        }}
                    />
                </div>

                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    Change made {formatDistanceToNowStrict(parseISO(snapshotLastChanged))} ago
                </Typography>
            </div>
        );
    }

    if (maxLoanAmountType === '$') {
        assistanceAmountText = formatCurrency(maxLoanAmount);

        if (snapshotWhenSelected && maxLoanAmount !== snapshotWhenSelected.maxLoanAmount) {
            assistanceAmountChangedTooltip = formatTooltip(formatCurrency(snapshotWhenSelected.maxLoanAmount));
        }
    } else if (maxLoanAmountType?.includes('%')) {
        const percentOfDisplay = formatPercentOfValue({
            maxLoanAmount,
            maxLoanAmountType
        });

        if (calculatedAssistanceAmount === null && maxLoanAmountCap === null) {
            assistanceAmountText = percentOfDisplay;
        } else {
            if (calculatedAssistanceAmount === null) {
                assistanceAmountText = `Up to ${formatCurrency(maxLoanAmountCap)}`;

                if (snapshotWhenSelected && maxLoanAmountCap !== snapshotWhenSelected.maxLoanAmountCap) {
                    assistanceAmountChangedTooltip = formatTooltip(
                        `Up to ${formatCurrency(snapshotWhenSelected.maxLoanAmountCap)}`
                    );
                }
            } else {
                assistanceAmountText = formatCurrency(calculatedAssistanceAmount);

                if (snapshotWhenSelected
                    && calculatedAssistanceAmount !== snapshotWhenSelected.calculatedAssistanceAmount) {
                    assistanceAmountChangedTooltip = formatTooltip(
                        formatCurrency(snapshotWhenSelected.calculatedAssistanceAmount)
                    );
                }
            }

            assistanceAmountSecondaryText = percentOfDisplay;
        }
    }

    const isMaxNotes = maxLoanAmountType === 'Notes';
    if (isMaxNotes && snapshotWhenSelected && maxLoanAmountNotes !== snapshotWhenSelected.maxLoanAmountNotes) {
        assistanceAmountChangedTooltip = formatTooltip(
            <Typography
                variant="caption"
                component="pre"
                whiteSpace="pre-wrap"
                className={styles.maxAmountNote}
            >
                {snapshotWhenSelected.maxLoanAmountNotes}
            </Typography>
        );
    }

    return (
        <Tooltip
            leaveDelay={100}
            title={assistanceAmountChangedTooltip}
            classes={{
                tooltip: styles.tooltip
            }}
        >
            <div className={styles.assistanceAmountContainer}>
                <Typography
                    variant={isMaxNotes ? 'caption' : 'body1'}
                    component={isMaxNotes ? 'pre' : 'span'}
                    whiteSpace={isMaxNotes ? 'pre-wrap' : 'nowrap'}
                    className={clsx({
                        [styles.maxAmountNote]: isMaxNotes
                    })}
                >
                    {isMaxNotes ? maxLoanAmountNotes : (
                        <>
                            {assistanceAmountText}

                            {assistanceAmountSecondaryText && (
                                <Typography
                                    component="p"
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {assistanceAmountSecondaryText}
                                </Typography>
                            )}
                        </>
                    )}
                </Typography>

                {assistanceAmountChangedTooltip !== null && (
                    <WarningAmber color="warning" />
                )}
            </div>
        </Tooltip>
    );
}

function formatPercentOfValue({
    maxLoanAmount, maxLoanAmountType
}: Pick<ClientProgram, 'maxLoanAmount' | 'maxLoanAmountType'>) {
    if (maxLoanAmountType === '%Price') {
        return `${maxLoanAmount}% of Purchase Price`;
    } else if (maxLoanAmountType === '%BaseLoan') {
        return `${maxLoanAmount}% of Base Loan Amount`;
    } else if (maxLoanAmountType === '%Loan') {
        return `${maxLoanAmount}% of Total Loan Amount`;
    } else if (maxLoanAmountType === '%Cost') {
        return `${maxLoanAmount}% of Acquisition Cost (Purchase Price + Closing Costs)`;
    } else if (maxLoanAmountType === '%DPReq') {
        return `${maxLoanAmount}% of Required Down Payment`;
    } else if (maxLoanAmountType === '%Value') {
        return `${maxLoanAmount}% of Appraised Value`;
    }

    return '';
}
